@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.form-login-logo {
  max-width: 300px;
}

.form-login {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
}

.bg-full {
  height:  100vh;
}

.form-login input,
.form-login input:-webkit-autofill,
.form-login input:-webkit-autofill:hover,
.form-login input:-webkit-autofill:focus,
.form-login select,
.form-login select:-webkit-autofill,
.form-login select:-webkit-autofill:hover,
.form-login select:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #222A3F;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form-login input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form-login input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

.form-login label {
  color: white;
}

.form-login h5 {
  color: white;
}

.form-login-loja label {
  color: black;
}

.form-login-loja h5 {
  color: black;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv
  .sidenav---sidenav-nav---3tvij
  .sidenav---sidenav-navitem---uwIJ-:hover
  > .sidenav---sidenav-subnav---1EN61 {
  display: none;
}

.sidenav---sidenav---_2tBP {
  max-width: 64px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 500;
}

.sidenav---navicon---3gCRo {
  width: 100%;
}

.sidenav---sidenav---_2tBP {
  background-color: rgba(0, 0, 0, 0.9);
  transition: none;
}

.sidenav---sidenav-subnav---1EN61 {
  border-radius: 4px;
  border: none;
}

.sidenav---sidenav-navitem---uwIJ-:hover {
  background-color: rgba(0, 0, 0, 1);
  padding-left: 10px;
  transition: padding-left 0.2s;
}

.sidenav---navtext---1AE_f:hover {
  padding-left: 10px;
  transition: padding-left 0.2s;
}

.nav-font,
.nav-font:visited,
.nav-font:hover,
.nav-font:active,
.nav-font:focus,
.nav-font:active:hover {
  font: 16px 'Roboto', sans-serif;
  font-weight: 500;
  text-decoration: none !important;
  color: white;
}

.nav-toggle,
.sidenav---sidenav-toggle---1KRjR {
  margin: 10px 0px 10px 0px;
}

#logo-nav {
  display: flex;
  margin: 10px 0px 10px 0px;
  max-width: 110px;
  width: 100%;
}

#perfil {
  display: flex;
  flex-direction: column;
  cursor: default;
  height: auto;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
}

.nome-usuario {
  max-width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
.nav-titulo {
  font-size: 16px;
  margin-top: 10px;
}

.nav-sub-titulo {
  font-size: 13px;
  background: none;
  border: none;
  color: white;
  text-align: start;
  margin-top: 10px;
}

.nav-sub-titulo:hover {
  font-size: 14px;
}

/* width */
nav::-webkit-scrollbar {
  width: 3px;
}

/* Track */
nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
nav::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidenav---navtext---1AE_f,
.sidenav---navitem---9uL5T {
  width: 100% !important;
  height: 100% !important;
}

.link-unstyled,
.link-unstyled:visited,
.link-unstyled:hover,
.link-unstyled:active,
.link-unstyled:focus,
.link-unstyled:active:hover {
  font-style: inherit;
  color: inherit;
  background-color: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  border-radius: inherit;
  border: inherit;
  outline: inherit;
  box-shadow: inherit;
  padding: inherit;
  vertical-align: inherit;
}

.main-painel {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 70px;
  background-color: #eeeeee;
  overflow-y: auto;
}

.form-logo {
  max-width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.form input,
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form select,
.form select:-webkit-autofill,
.form select:-webkit-autofill:hover,
.form select:-webkit-autofill:focus,
.form textarea,
.form textarea:-webkit-autofill,
.form textarea:-webkit-autofill:hover,
.form textarea:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #222A3F;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

input::-webkit-input-placeholder {
  color: green !important;
}

input:-ms-input-placeholder {
  color: green !important;
}

input::placeholder {
  color: green !important;
}

.card-dash {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #222A3F;
  align-items: center;
  width: 100%;
}

.card-dash-titulo {
  color: white;
  font-size: 18px;
  width: 70%;
  font-weight: 500;
  overflow-x: hidden;
}

.dash-table-titulo span {
  overflow-x: hidden;
}

.card-dash-subtitulo {
  color: white;
  font-size: 30px;
  text-align: end;
  width: 30%;
}
.card-dash-footer {
  width: 100%;
  background-color: white;
  height: 2px;
}

.dash-table {
  border-radius: 0px 0px 4px 4px;
}

.dash-col {
  border: none;
}

.dash-table-titulo {
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: #171717;
  background-color: #eeeeee;
  border-radius: 4px 4px 0px 0px;
  display: flex;
}

.dash-table-body {
  font-size: 14px;
}

.dash-table-header {
  font-size: 14px;
}

.tr-gray {
  background-color: #777777;
}

h4 {
  color: white;
}

.lojas .loja-checkbox input {
  margin-bottom: 0;
  margin-right: 10px;
}


.loja-checkbox {
  display: flex;
  justify-content: left;
  align-items: center;
}

.loja-checkbox label {
  font-size: 14px;
}

.aviso {
  font-size: 12px;
  color: black;
}

label {
  font-size: 12px;
  color: black;
}

.react-select__value-container {
  height: 50px;
}

.react-select__control.css-yk16xz-control {
  border-color: #005a99;
}

.lista-item {
  color: black;
  padding: 5px;
  margin: 0;
}

.lista {
  list-style: none;
  text-align: center;
  width: 100%;
}

.btn-adicionar {
  padding: 10px 15px 10px 15px;
  height: 44px;
  background-color: #005a99;
  font-weight: bold;
  color: black;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-remover {
  height: 44px;
  font-weight: bold;
  color: black;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;
  background-color: black;
  color: #005a99;
}

.btn-remover:hover {
  background-color: black;
}

#export-menu {
  display: block;
  height: auto;
  position: absolute;
  padding: 10px 0px 0px 0px;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: black;
  z-index: 10;
  top: 1;
  right: 0;
}

#export-menu button {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  margin-bottom: 10px;
}

#export-menu button:hover {
  background-color: #cccccc;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
  margin: 0 0 -10px 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.card-detalhe {
  width: 100%;
  height: 190px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.card-infos {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin: auto;
  margin-top: 10px;
}

.card-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-links a,
.card-links button {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.card-links a {
  color: #005a99;
}

.card-links a:hover {
  color: #005a99;
}

.card-imagem {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-imagem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-imagem video {
  width: 100%;
}

.card-midia {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-midia-titulo {
  font-weight: bold;
}

.table {
  width: 100%;
  background-color: white;
  text-align: center;
}

.h-container {
  height: 85.77% !important;
}

.footer {
  height: auto;
  background-color: rgb(18, 52, 116);
  font-size: 12px;
  width: 100%;
  color: white;
}
.footer-fixed {
  height: auto;
  background-color: rgb(18, 52, 116);
  font-size: 12px;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: white;
}
.politica-privacidade {
  color: white;
}
.politica-privacidade:hover {
  color: white;
  text-decoration: none;
}



.btn-primario3{
  background-color: rgb(201,81,61) !important;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.header-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background-color: #222a3f;
  font-size: 14px;
  width: 100%;
}

.header-home-auto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  height: auto;
  background-color: #222a3f;
  font-size: 14px;
  width: 100%;
  background-image: url('/imagens/barra-menu.png');
  background-size: cover;
  background-repeat: repeat-x;
  padding-bottom: 16px;
}

.header-home span,
.header-home a,
.header-home-auto span,
.header-home-auto a {
  color: white;
}

.header-home a:hover,
.header-home-auto a:hover {
  text-decoration: none;
  color: white;
}

.nav-sair {
  background: none;
  border: none;
  color: white;
  text-align: start;
}

.nav-brand {
  width: 70px;
}

.header-acoes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header-acoes button {
  background-color: transparent;
  color: white;
}

.link-colored {
  background-color: #4a76b9;
  border-radius: 10px;
  width: 110px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link-colored:hover {
  background-color: #5b87ca;
}

.border-colored {
  border: 1px solid white;
  border-radius: 10px;
  width: 110px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.border-colored:hover {
  background-color: #3f443a;
}

.nav-indicacao {
  min-width: 170px;
  padding: 4px 8px;
  /* height: 50px; */
  background-color: #6feffa;
  font-weight: bold;
  color: rgb(29, 59, 125) !important;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

.nav-link {
  font-weight: bold;
}

.nav-link:hover {
  padding-top: 3px;
}

.nav-link:hover {
  padding-top: 3px;
}

.nav-link {
  color: white !important;
}
.navbar .navbar-toggler-icon {
  color: white;
}

.nav-menu {
  color: white;
}

.nav-logo {
  max-width: 160px;
}
.navbar-toggler {
  width: 100%;
}

.modal-body {
  display: flex;
  justify-content: center;
}

#video-modal .modal-content {
  /* background-color: transparent; */
  border: none;
}

#video-modal video {
  max-height: 90vh;
  width: auto !important;

  margin: 0 auto;
}

.banner-home {
  margin: 50px;
  max-width: 100%;
}

.h-container {
  height: 87.77% !important;
}

@media only screen and (max-width: 844px) {
  .h-container {
    height: 79.69% !important;
  }
}

form.form.fundo-claro label {
  color: black !important;
}

form.form.loja {
  max-width: 100%;
}

.carousel-wrapper {
  img {
    border-radius: 20px;
  }
}

.teste {
  color: black;
}

.card-premio-nome {
  height: 50px;
}

.btn-resgatar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #222a3f;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-resgatar:hover {
  color: #222a3f;
  border: 1px solid #222a3f;
  background-color: white;
}

.input-busca {
  border: none;
  color: black;
  width: 150px;
  margin: 0;
  padding: 0;
}

.input-busca::-webkit-input-placeholder {
  color: grey !important;
  font-size: 12.5px;
}

.input-busca:-ms-input-placeholder {
  color: grey !important;
  font-size: 12.5px;
}

.input-busca::placeholder {
  color: grey !important;
  font-size: 12.5px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px 0px;
  z-index: 999;
}

.categoria {
  background-color: #f1f1f1;
  color: #646363;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 8px;
  font-size: 14px;
}

.categoria:hover {
  background-color: lightgray;
  cursor: pointer;
}

.categoria.active {
  background-color: #222a3f;
  color: white;
}

.categoria.active:hover {
  background-color: #222a3f;
  cursor: pointer;
}

.limpar-filtros button,
.limpar-filtros i {
  color: #222a3f !important;
  font-size: small !important;
  cursor: pointer;
}

.limpar-filtros button:hover {
  -webkit-text-decoration: underline #222a3f !important;
          text-decoration: underline #222a3f !important;
}

.limpar-filtros {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hr-loja {
  margin-top: -5px;
}

.hr-categoria {
  background-color: #222a3f;
  border: 1.3px solid #222a3f;
}

.filter-city-item {
  padding: 8px 16px;
  background-color: #222a3f;
  color: #fff;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-city-item:hover {
  background-color: #fff;
  color: #222a3f;
  border: 1px solid #222a3f;
  cursor: pointer;
  transition: 50ms;
}

.filter-city-item p {
  margin: 0;
}

.awards-container {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;

  overflow: auto;
}

.awards-container img {
  border: none;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .card-premio-nome {
    height: 90px;
  }
}

@media (max-width: 767px) {
  .sticky-search {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    z-index: 999;
    background-color: #fff;
  }
}

@media screen and (min-width: 576px) {
  .input-busca {
    border: none;
    color: black;
    width: 250px;
  }

  .input-busca::-webkit-input-placeholder {
    color: grey !important;
    font-size: 14px;
  }

  .input-busca:-ms-input-placeholder {
    color: grey !important;
    font-size: 14px;
  }

  .input-busca::placeholder {
    color: grey !important;
    font-size: 14px;
  }
}

.premio-titulo {
  font-size: 30px;
  font-weight: 500;
}

.premio-subtitulo {
  font-size: 18px;
  font-weight: 400;
}

.premio-descricao {
  font-size: 12px;
}

.premio-status {
  font-size: 12px;
  color: #fff;
  line-height: 18px;
  background: #6dbe14;
  text-transform: uppercase;
  padding: 3px 15px;
  font-weight: bold;
  border-radius: 999px;
}

.btn-add-qtde,
.btn-sub-qtde {
  width: 30px;
  height: 30px;
  background-color: #005a99;
  font-weight: bold;
  color: black;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.list-item {
  margin-left: 2rem;
}

.btn-slide {
  background-color: transparent;
  border: none;
  color: #333 !important;
}

.btn-atualizar {
  padding: 5px;
  width: 200px;
  height: 44px;
  background-color: #222A3F;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-atualizar:hover {
  color: #222A3F;
  border: 1px solid #222A3F;
  background-color: white;
}

.tabela-header-claro {
  text-align: center !important;
}

.tabela-body-claro {
  text-align: center !important;
}

.pagination.react-bootstrap-table-page-btns-ul li.active a {
  color: white !important;
  background-color: #222A3F !important;
  border: none;
}

.pagination.react-bootstrap-table-page-btns-ul li a {
  color: #222A3F !important;
  background-color: white;
  border: none;
}

.dropdown-item a {
  color: black;
}

#pageDropDown {
  color: white !important;
  background-color: #222A3F !important;
  border: none;
  box-shadow: none;
}

div.col-md-6.col-xs-6.col-sm-6.col-lg-6,
.react-bootstrap-table-pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.page-link:focus {
  box-shadow: none !important;
}

.sort-asc,
.sort-desc {
  color: black;
}

.react-bootstrap-table {
  overflow-y: auto;
}

.resgates-container {
  display: flex;
  flex-direction: column;

  grid-gap: 8px;

  gap: 8px;
}

.resgates-item {
  padding: 8px 16px;
  border-radius: 8px;

  color: #fff;
  background-color: #005a99;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resgates-item:hover {
  background-color: #fff;
  cursor: pointer;
  color: #005a99;

  border: 1px solid #005a99;
}

.resgates-item i {
  color: #fff;
}

.resgates-item:hover i {
  color: #005a99;
}

#export-menu {
  display: block;
  height: auto;
  position: absolute;
  padding: 10px 0px 0px 0px;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: black;
  z-index: 10;
  top: 1;
  right: 0;
}

#export-menu button {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  margin-bottom: 10px;
}

#export-menu button:hover {
  background-color: #cccccc;
}

.contato-mensagem {
  min-height: 100px;
}

.btn-enviar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #222A3F;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-enviar:hover {
  color: #222A3F;
  border: 1px solid #222A3F;
  background-color: white;
}

.scannerContent {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .scannerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
}

.contentInput {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 16px;
  -webkit-column-gap: 16px;
          column-gap: 16px;
}

@media screen and (max-width: 991px) {
  .contentInput {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* row-gap: 4px; */
  }
}


#interactive {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

#interactive > canvas {
  display: none;
}


.modal-body {
  max-height: 80%;
  overflow-y: auto;
}

.card-cartao {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #005a99;
  align-items: center;
  width: 100%;
  min-height: 115px;
  height: 100%;
}

.card-cartao-titulo {
  color: white;
  font-size: 18px;
  width: 35%;
  font-weight: 500;
  overflow-x: hidden;
  text-align: center;
}

.cartao-table-titulo span {
  overflow-x: hidden;
}

.card-cartao-subtitulo {
  color: white;
  font-size: 22px;
  text-align: end;
  width: 65%;
}
.card-cartao-footer {
  width: 100%;
  background-color: white;
  height: 1px;
  color: white;
}

@media screen and (max-width: 991px) {
  .card-cartao {
    font-size: 22px;
    display: flex;
    border-radius: 4px;
    height: 100px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: #005a99;
    align-items: center;
    width: 100%;
    min-height: 252px;
    height: 100%;
  }

  .card-cartao-titulo {
    color: white;
    font-size: 22px;
    width: 35%;
    font-weight: 500;
    overflow-x: hidden;
    text-align: center;
  }

  .card-cartao-subtitulo {
    color: white;
    font-size: 50px;
    text-align: end;
    width: 65%;
  }
  .card-cartao-footer {
    width: 100%;
    background-color: white;
    height: 1px;
    color: white;
  }
}

.f-24 {
  font-size: 24px;
}

.card-sem-cartao {
  font-size: 18px;
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #005a99;
  align-items: center;
  width: 100%;
  min-height: 115px;
  height: 100%;
  justify-content: center;
  text-align: center;
  color: white;
}

.card-sem-cartao-footer {
  width: 100%;
  background-color: white;
  height: 1px;
  color: white;
}

@media screen and (max-width: 991px) {
  .card-sem-cartao {
    font-size: 22px;
    display: flex;
    border-radius: 4px;
    height: 100px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: #005a99;
    align-items: center;
    width: 100%;
    min-height: 252px;
    height: 100%;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .card-sem-cartao-footer {
    width: 100%;
    background-color: white;
    height: 1px;
    color: white;
  }
}

.cartao-info-verde {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #1e7e34;
}

.cartao-info-vermelho {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #ff0000;
}

.cartao-info-preto {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #000000;
}

.cartao-info-marrom {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #86724c;
}

.card-infos {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin: auto;
  margin-top: 10px;
}

.card-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-links a {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.card-links a {
  color: #005a99;
}

.card-links a:hover {
  color: #005a99;
}

.card-imagem {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-imagem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-imagem video {
  width: 100%;
}

.card-midia {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-midia-titulo {
  font-weight: bold;
}

.wrapper {
  height: 600px;
  display: flex;
  padding: 16px;
  grid-gap: 16px;
  gap: 16px;

  align-items: center;
}

.wrapper:nth-child(odd) {
  flex-direction: row;
}

.wrapper:nth-child(even) {
  flex-direction: row-reverse;
}

.img-wrapper {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.description {
  text-align: justify;
  overflow: hidden;
  max-height: 384px;
}

.description-wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 38px 0;
}

.categories-container {
  img {
    border-radius: 20px;
  }
}

.img-wrapper img {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}

@media (max-width: 992px) {
  .wrapper {
    flex-direction: column !important;
    height: auto;
  }

  .img-wrapper,
  .description-wrapper {
    width: 100%;
    height: 100%;
  }

  .img-wrapper img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
}

.card-premio-nome {
  height: 50px;
}

.btn-resgatar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #222a3f;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-resgatar:hover {
  color: #222a3f;
  border: 1px solid #222a3f;
  background-color: white;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px 0px;
  z-index: 999;
}

.categoria {
  background-color: #f1f1f1;
  color: #646363;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 8px;
  font-size: 14px;
}

.categoria:hover {
  background-color: lightgray;
  cursor: pointer;
}

.categoria.active {
  background-color: #222a3f;
  color: white;
}

.categoria.active:hover {
  background-color: #222a3f;
  cursor: pointer;
}

.limpar-filtros button,
.limpar-filtros i {
  color: #222a3f !important;
  font-size: small !important;
  cursor: pointer;
}

.limpar-filtros button:hover {
  -webkit-text-decoration: underline #222a3f !important;
          text-decoration: underline #222a3f !important;
}

.limpar-filtros {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hr-style {
  width: 100%;
  border-top: 3px solid #222a3f;
}
/* .hr-categoria {
  background-color: #222a3f;
  border: 1.3px solid #222a3f;
} */

@media screen and (min-width: 768px) and (max-width: 991px) {
  .card-premio-nome {
    height: 90px;
  }
}

.content-wrapper {
  margin: 1rem 0;
  display: flex;
  flex-direction: column !important;
  grid-gap: 1rem;
  gap: 1rem;

  height: 100%;
  width: 100%;

  overflow: hidden;
}

.header {
  background-color: #1e375d;
  width: 100%;
  display: flex !important;
  padding: 1rem;
  justify-content: center;
}

.header img {
  max-width: 200px;
  width: 50%;
  height: auto;
}

.header img:last-child {
  padding-left: 1.5rem;
  border-left: 2px solid #fff;
}

.vertical-section {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  font-size: 4rem;
  color: dodgerblue;

  padding: 0 2rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.description {
  font-size: 1rem;
  text-align: center;
  color: #333;

  @media (max-width: 768px) {
    max-width: 75%;
  }
}

.btn-atualizar {
  padding: 5px;
  width: 200px;
  height: 44px;
  background-color: #4a76b9;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-atualizar:hover {
  background-color: #5b87ca;
}

.premio-card {
    border: 1px solid #e4e7e9;
    border-radius: 4px;
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .premio-imagem {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .premio-info {
    margin-top: 1rem;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .premio-pontos {
    font-weight: bold;
    color: #222A3F;
    margin: 0.5rem 0;
  }
  
  .categoria-item {
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .categoria-item:hover {
    background-color: #f5f5f5;
  }
  
  .categoria-item.active {
    background-color: #222A3F;
    color: white;
    border-radius: 4px;
  }
  
  .search-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
.h-container {
  height: 85.77% !important;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialased;
}

body,
input,
button {
  font: 1rem 'Roboto', sans-serif;
}

button {
  cursor: pointer;
}
.a-clean,
.a-clean:hover,
.a-clean:active {
  color: inherit;
  text-decoration: inherit;
}

.Toastify__toast--success {
  background-color: #222A3F;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--error {
  background-color: #222A3F;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--info {
  background-color: #222A3F;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--warning {
  background-color: #222A3F;
  border-radius: 4px;
  text-align: center;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.bg-black {
  background-color: black !important;
}

.f-black {
  color: black;
}

.f-white {
  color: white;
}

.f-blue {
  color: #222A3F;
}

.btn-primario {
  margin: 5px 0 0;
  height: 44px;
  background-color: #222A3F !important;
  font-weight: bold;
  color: white !important;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
/*
.btn-primario:hover {
  background-color: white;
  color: #222A3F;
  border: 1px solid #222A3F;
} */

.btn-secundario {
  margin: 5px 0 0;
  height: 44px;
  background-color: black;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.btn-secundario:hover {
  background-color: #a6b438;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-white {
  color: white;
}

.f-green {
  color: green;
}

.f-red {
  color: red;
}

.hr-loja {
  width: 100%;
  border-top: 3px solid #222A3F;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.row:before,
.row:after {
  display: none !important;
}

.caret-4-desc:after {
  content: '\2193';
}
.caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}
.caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

ul.pagination.react-bootstrap-table-page-btns-ul {
  margin-top: 1rem;
}

.btn-limpo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

div.ril-toolbar.ril__toolbar {
  background-color: unset;
}

.table-dark thead th {
  color: white;
}

.table-dark td {
  background-color: white;
  color: black;
  border-color: #e4e7e9 !important;
}


.table-dark td:focus {
  outline: none !important;
  border: 10px solid black;
}

.table-dark tfoot {
  background-color: white;
  color: black;
}

.table-dark {
  white-space: nowrap;
  font-size: 14px;
  background-color: #777777 !important;
}

.barra-busca {
  float: right !important;
  border: none;
  outline: 0 !important;
  width: 150px !important;
  color: black;
}

.textarea {
  padding: 10px !important;
  min-height: 100px;
}

.input-select {
  width: auto;
  height: 35px;
  border: 1px solid #222A3F;
  border-radius: 4px;
  background-color: white;
}

.input-theme {
  height: 35px !important;
  border: 1px solid #222A3F !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme::-webkit-input-placeholder {
  color: black !important;
}

.input-theme:-ms-input-placeholder {
  color: black !important;
}

.input-theme::placeholder {
  color: black !important;
}

input,
select {
  padding-left: 16px !important;
}

.react-select__placeholder {
  padding-left: 15px;
}

.btn-busca {
  height: 35px;
  width: 35px;
  background-color: #222A3F;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 25px;
}

@media screen and (min-width: 576px) {
  .btn-busca {
    height: 35px;
    width: 35px;
    background-color: #222A3F;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
  }
}

.search-label {
  max-width: 250px;
}

@media screen and (max-width: 768px) {
  .search-label {
    width: 100% !important;
    max-width: 100%;
  }
}

.btn-primario-slim {
  height: 35px;
  background-color: #222A3F;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 576px) {
  .btn-primario-slim {
    height: 35px;
    background-color: #222A3F;
    font-weight: bold;
    color: rgb(255, 255, 255);
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
  }
}

.alert-antifraude,
.alert-antifraude:hover,
.alert-antifraude:visited {
  font-size: 14px;
  margin-left: 5px;
  color: red !important;
  text-decoration: none;
}

.link-clean,
.link-clean:hover,
.link-clean:visited {
  color: black;
}

.form-control.editor.edit-text:focus {
  box-shadow: none;
  border: 1px solid #222A3F;
}

.premio-desc {
  min-height: 100px;
}

select {
  color: #222A3F
}

.btn-cinza {
  margin: 5px 0 0;
  height: 44px;
  background-color: #777777 !important;
  font-weight: bold;
  color: white !important;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary {
  background-color: #222A3F !important;
  border-color: #222A3F !important;
}

.btn-primary:hover {
  background-color: #1b2231 !important;
  border-color: #1b2231 !important;
}

.iiz {
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: zoom-in;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.iiz__btn:before {
  content: ' ';
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}

.iiz__close::before {
  content: ' ';
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}

