#interactive {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

#interactive > canvas {
  display: none;
}

